import { AuthenticationState } from './../models/enums/authentication-state.enum';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, of, switchMap, timer, map, tap } from 'rxjs';
import { Claims } from '../models/claims';
import { Permissie } from '../models/enums/permissie.enum';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	private readonly lastUsedEmailKey = 'lastUsedEmail';
	private readonly bypassHintsKey = 'bypassHints';

	private readonly fiveMinutesInMillis = 5 * 60 * 1000;

	private _authenticationState$ = new BehaviorSubject<AuthenticationState>(AuthenticationState.CheckAuthenticated);
	private _claims = new BehaviorSubject<Claims | undefined>(undefined);
	private lastUsedEmail: string;

	get isAuthenticated$() {
		return this._authenticationState$.pipe(switchMap((state) => of(state === AuthenticationState.Authenticated)));
	}

	get claims$() {
		return this._claims.asObservable();
	}

	get permissies$() {

		return this._claims.asObservable().pipe(map((c) => c.permissieIds));
	}

	get isAuthenticating() {
		return (
			this._authenticationState$.getValue() === AuthenticationState.CheckAuthSilent ||
			this._authenticationState$.getValue() === AuthenticationState.CheckAuthenticated
		);
	}

	get isAtSignedOffPage() {
		return document.location.href.includes('/loggedout');
	}

	get isAtErrorPage() {
		return document.location.href.includes('/error');
	}

	get isAuthenticated() {
		return (
			this._authenticationState$.getValue() === AuthenticationState.Authenticated ||
			this._authenticationState$.getValue() === AuthenticationState.CheckAuthSilent
		);
	}

	get claims(): Claims | undefined {
		return this._claims?.getValue();
	}

	get permissies(): Permissie[] {
		return this._claims?.getValue()?.permissieIds;
	}

	constructor(
		private httpClient: HttpClient,
		private router: Router
	) {
		console.log('authentication.service constructor!');
		this.initLastUsedEmail();
		this.enforceAuthenticationEnrollment();
		this.checkAuthenticated();
	}

	heeftPermissie(permissie: Permissie): boolean {
		return this.permissies.includes(permissie);
	}

	heeftEnkelePermissies(permissies: Permissie[]): boolean {
		return this.permissies.some((p) => permissies.includes(p));
	}

	heeftAllePermissies(permissies: Permissie[]): boolean {
		return this.permissies.every((p) => permissies.includes(p));
	}

	private login() {
		this.router.navigate(['/']);

		let uri = `/auth/login?`;

		if (this.shouldBypassHints()) {
			uri += `bypasssHints=true`;
		} else if (this.lastUsedEmail) {
			uri += `loginHint=${this.lastUsedEmail}`;
		}

		this.httpClient.get(uri).subscribe();
	}

	logout() {
		this.clearHints();
		this.httpClient.get('/auth/logout').subscribe();
	}

	private checkAuthenticated() {
		this._authenticationState$.subscribe((state) => {
			switch (state) {
				case AuthenticationState.CheckAuthenticated:
				case AuthenticationState.CheckAuthSilent:
					if (this.isAtSignedOffPage || this.isAtErrorPage) {
						// Wait for manual confirmation of the user to continue auth flow.
						return;
					}

					this.httpClient
						.get<Claims>('/auth/isAuthenticated')
						.pipe(
							catchError((_) => {
								this.login();

								return of();
							}),
							tap((claims) => {
								if (state === AuthenticationState.CheckAuthenticated) {
									// this.router.navigate(['/']);
									this.setBypassDomainHint(false); // Clear bypassing domain hint.
									this.persistUsedEmail(claims.email); // Persist email to pass as a hint.
									console.log('claims', claims);
									this._claims.next(claims);
								}
								this._authenticationState$.next(AuthenticationState.Authenticated);
							})
						)
						.subscribe();
					break;
			}
		});
	}

	// Clear the provided hints so when starting the auth flow the user has to select which provider or account he wants to sign in.
	clearHints() {
		this.persistUsedEmail(undefined);
		this.setBypassDomainHint(this.heeftPermissie(Permissie.OmzeilDomainHintOmgeving));
	}

	setAuthenticationState(authenticationState: AuthenticationState) {
		this._authenticationState$.next(authenticationState);
	}

	private persistUsedEmail(email: string) {
		this.lastUsedEmail = email;
		localStorage.setItem(this.lastUsedEmailKey, email);
	}

	private setBypassDomainHint(value: boolean) {
		value ? localStorage.setItem(this.bypassHintsKey, 'true') : localStorage.removeItem(this.bypassHintsKey);
	}

	private getLastUsedEmail() {
		return localStorage.getItem(this.lastUsedEmailKey);
	}

	private shouldBypassHints() {
		return localStorage.getItem(this.bypassHintsKey) === 'true';
	}

	private initLastUsedEmail() {
		return this.persistUsedEmail(this.getLastUsedEmail());
	}

	/**
	 * Enforce the user to re-enroll for authentication silently every 5 minutes.
	 */
	private enforceAuthenticationEnrollment() {
		timer(this.fiveMinutesInMillis, this.fiveMinutesInMillis).subscribe(() =>
			this._authenticationState$.next(AuthenticationState.CheckAuthSilent)
		);
	}
}
