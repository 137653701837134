<div class="mx-4">
	<p class="mt-0">
		In dit scherm kun je eenvoudig bepalen hoe je op de hoogte gehouden wilt worden van verschillende gebeurtenissen. Voor elke gebeurtenis
		kun je aangeven welke vorm van communicatie jouw voorkeur heeft:
	</p>

	<ul>
		<li><strong>Geen</strong> Je ontvangt geen meldingen.</li>
		<li><strong>Notificatie</strong> Je krijgt een melding binnen de applicatie.</li>
		<li><strong>Email</strong> Je ontvangt een bericht via email.</li>
		<li><strong>Notificatie en email</strong> Je krijgt zowel een melding in de applicatie als een email.</li>
	</ul>
	<p>Kies per gebeurtenis de optie die het beste bij jou past, zodat je precies weet wat er speelt, op de manier die jij prettig vindt!</p>

	<form id="notificatiesForm" [formGroup]="form" (ngSubmit)="saveChanges()">
		<ng-container formArrayName="notificatieConfiguraties">
			<ng-container *ngFor="let item of form.get('notificatieConfiguraties')['controls']; let i = index" [formGroupName]="i">
				<div class="w-50 flex flex column justify-content-between align-items-center mb-4">
					<span class="font-bold">{{ item.controls['topic'].value }}</span>
					<div>
						<!-- <app-form-field title="Soort" [control]="item.controls['type']"> -->

						<p-dropdown
							[loading]="loadingDropdowns"
							appendTo="body"
							[formControl]="item.controls['type']"
							[options]="notificatieTypeOptions"
							optionValue="value"
							optionLabel="label"
							placeholder="Maak een keuze.."
							styleClass="w-full z-5">
						</p-dropdown>

						<!-- <select [formControl]="item.controls['type']" class="form-control">
										<option [ngValue]="null" selected disabled>Kies een soort</option>
										<option *ngFor="let type of NotificatieType | enumKeys: false" [ngValue]="type.key">
											{{ type.value }}
										</option>
									</select> -->
						<!-- </app-form-field> -->
					</div>
				</div>
			</ng-container>
		</ng-container>
		<div class="flex justify-content-end gap-3">
			<p-button type="submit" label="Opslaan" />
		</div>
	</form>
</div>
