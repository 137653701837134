import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class SubscriptionHolder implements OnDestroy {
	protected readonly unsubscribe$ = new Subject<void>();

	public ngOnDestroy(): void {
		this.clearSubscriptions();
	}

	protected clearSubscriptions() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
