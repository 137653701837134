import { SortOrder } from '../models/enums/sort-order.enum';

export const sortArrayByKey = (key: string, order: SortOrder = SortOrder.Ascending) => {
	return (a: any, b: any) => {
		if (!key || order === SortOrder.None || !a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
			return 0;
		}

		const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
		const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}

		return order === SortOrder.Descending ? comparison * -1 : comparison;
	};
};
