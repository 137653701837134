export const formatEnumValueAsReadableText = (value: any, enumType: any): string | null => {
	if (value == null || value === undefined) {
		return null;
	}

	if (!enumType) {
		return value;
	}

	if (!enumType[value]) {
		return null;
	}

	if (!isNaN(parseInt(value, 10))) {
		const enumWords: string[] = enumType[value].split(/(?=[A-Z])/);
		let enumValue = enumWords[0];
		if (enumWords.length > 1) {
			let lastWordWasSingleChar = enumValue.length === 1;
			let noSpaceBeforeWord = false;
			for (let i = 1; i < enumWords.length; i++) {
				if (enumWords[i].length === 1) {
					enumValue += lastWordWasSingleChar ? enumWords[i] : ` ${enumWords[i]}`;
					lastWordWasSingleChar = true;
				} else if (enumWords[i] === 'Plus') {
					lastWordWasSingleChar = false;
					enumValue += ' +';
				} else if (enumWords[i] === 'Minus') {
					lastWordWasSingleChar = false;
					enumValue += ' -';
				} else if (enumWords[i] === 'And') {
					lastWordWasSingleChar = false;
					enumValue += ' &';
				} else if (enumWords[i] === 'Slash') {
					lastWordWasSingleChar = false;
					enumValue += ' /';
				} else if (enumWords[i] === 'Space') {
					lastWordWasSingleChar = false;
				}
				else if (enumWords[i] === '_') {
					lastWordWasSingleChar = false;
				} else if (enumWords[i] === 'Startbracket') {
					lastWordWasSingleChar = false;
					noSpaceBeforeWord = true;
					enumValue += ' (';
				} else if (enumWords[i] === 'Endbracket') {
					lastWordWasSingleChar = false;
					enumValue += ')';
				} else {
					lastWordWasSingleChar = false;
					if (noSpaceBeforeWord) {
						noSpaceBeforeWord = false;
						enumValue += `${enumWords[i].toLowerCase()}`;
					} else {
						enumValue += ` ${enumWords[i].toLowerCase()}`;
					}
				}
			}

			enumValue = enumValue.replace(' / ', '/');
			enumValue = enumValue.replace(' - ', '-');
		}

		return enumValue;
	}

	return null;
};
