import { Component, Injector, OnInit } from '@angular/core';
import { CardModule } from 'primeng/card';
import { FormComponent } from '../../../core/components/form.component';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormFieldComponent } from '../form-field/form-field.component';
import { NotificatieType } from '../../../core/models/enums/notificatie-type.enum';
import { EnumKeysPipe } from '../../../core/pipes/enum-keys.pipe';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { NotificatieConfiguratie } from '../../../core/models/dtos/notificaties/notificatie-configuratie';
import { NotificatieConfiguratieService } from '../../../core/services/api/notificatie-configuratie-service';
import { takeUntil } from 'rxjs';
import { ToastrService } from '../../../core/services/toastr.service';
import { DropdownModule } from 'primeng/dropdown';
import { NotificatieSettings } from '../../../core/models/enums/notificatie-topics.enum';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-notificatie-instellingen',
	standalone: true,
	imports: [
		CommonModule,
		CardModule,
		ButtonModule,
		ReactiveFormsModule,
		FormsModule,
		FormFieldComponent,
		ChipModule,
		EnumKeysPipe,
		DropdownModule
	],
	templateUrl: './notificatie-instellingen.component.html',
	styleUrl: './notificatie-instellingen.component.scss'
})
export class NotificatieInstellingenComponent extends FormComponent implements OnInit {
	constructor(
		injector: Injector,
		private notificatieConfiguratieService: NotificatieConfiguratieService,
		private toastrService: ToastrService,
		private dialogRef: DynamicDialogRef
	) {
		super(injector);
	}

	notificatieConfigurations: NotificatieConfiguratie[] = [];
	NotificatieType = NotificatieType;
	notificatieTypeOptions: Array<any> = [];
	loadingDropdowns: boolean = false;

	override form: FormGroup<{
		notificatieConfiguraties: FormArray;
	}>;

	ngOnInit() {
		this.initNotificatieTypes();
		this.initForm();
		this.addConfiguraties();
		this.loadApplicationConfigurations();
	}

	afterValidityEnsured(): Promise<any> {
		throw new Error('Method not implemented.');
	}

	initForm(): Promise<void> {
		return new Promise(() => {
			(this.form = this.formBuilder.group({
				notificatieConfiguraties: this.formBuilder.array([])
			})),
				(reject) => { };
		});
	}

	initNotificatieTypes() {
		const stringKeys = Object.keys(NotificatieType)
			.filter((v) => isNaN(Number(v)))

		this.notificatieTypeOptions = stringKeys.map((key, index) => ({ label: key.replaceAll('Space', ' '), value: index }))
	}


	saveChanges() {
		if (this.form.valid) {
			var configs = this.form.controls.notificatieConfiguraties.value as NotificatieConfiguratie[];
			this.notificatieConfiguratieService
				.addOrUpdate(configs)
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe({
					next: (result) => {
						this.toastrService.success('Notificaties opslaan', 'De notificatie instellingen zijn succesvol opgeslagen');
						this.dialogRef.close();
					},
					error: () => {
						this.toastrService.error('Notificaties opslaan', 'Er is een fout opgetreden bij het opslaan van de notificatie instellingen');
					}
				});
		}
	}

	addConfiguraties(): void {
		for (const topic of new NotificatieSettings().topics) {
			const configuratieForm = this.formBuilder.group({
				id: new FormControl(0, []),
				topic: new FormControl(topic, []),
				type: new FormControl(NotificatieType.Notificatie, [])
			});
			this.form.controls.notificatieConfiguraties.push(configuratieForm);
		}
	}

	loadApplicationConfigurations(): void {
		this.loadingDropdowns = true;
		this.notificatieConfiguratieService
			.getAllByApplication()
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: (res) => {
					console.log('loadApplicationConfigurations');
					this.notificatieConfigurations = res;
					for (const item of this.notificatieConfigurations) {
						var formArray = this.form.controls.notificatieConfiguraties.controls.find(
							(c) => c['controls']['topic'].value === item.topic
						) as FormGroup;
						if (formArray !== undefined) {
							formArray['controls']['id'].setValue(item.id);
							formArray['controls']['type'].setValue(item.type);
						}
					}
					this.loadingDropdowns = false;
				},
				error: () => {
					this.loadingDropdowns = false;
				}
			});
	}
}
