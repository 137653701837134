import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-form-field-title',
	template: `
		@if (titleIcon != undefined) {
			<i [class]="titleIcon"></i>
		}
		<label class="font-bold">
			{{ title }}
		</label>
	`,
	standalone: true,
	imports: [CommonModule]
})
export class FormFieldTitleComponent {
	@Input() title: string = '[Choose a title]';
	@Input() titleIcon: string = undefined;
}
