export enum Permissie {
	BerichtPlaatsen = 100,
	EigenBerichtBewerken = 101,
	AlleBerichtenBewerken = 102,
	EigenBerichtVerwijderen = 103,
	AlleBerichtenVerwijderen = 104,
	EigenBerichtDiscussieSluiten = 105,
	AlleBerichtDiscussieSluiten = 106,
	ReactiesEigenBerichtVerwijderen = 107,
	EigenReactieVerwijderen = 108,
	AlleReactiesVerwijderen = 109,
	ReactiePlaatsen = 110,
	BerichtUrgentMaken = 111,
	Verpersoonlijken = 112,
	PollPlaatsen = 113,
	LabelsBeheer = 114,
	SnelkoppelingenBeheer = 115,
	GebruikersBeheer = 116,
	RollenBeheer = 117,
	GebruikerRollenBeheer = 118,
	OmzeilDomainHintOmgeving = 119,
	// frontend only - dient aan label gekoppeld te worden.
	OrganisatieBerichtPlaatsen = 999
}
