import { Injectable, Injector } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { NotificatieInstellingenComponent } from '../../shared/components/notificatie-instellingen/notificatie-instellingen.component';

@Injectable({
	providedIn: 'root'
})
export class SettingsDialogService {
	readonly dialogService: DialogService;

	constructor(private injector: Injector) {
		this.dialogService = this.injector.get(DialogService);
	}

	open() {
		this.dialogService.open(NotificatieInstellingenComponent, {
			header: 'Stel je notificatievoorkeuren in',
			width: '50%',
			modal: true,
			dismissableMask: true,
			contentStyle: { overflow: 'auto' },
			baseZIndex: 10000,
			maximizable: false
		});
	}
}
