import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-invalid-control-feedback-message',
	template: `
		<small class="text-red-300">
			@if (control?.hasError('required')) {
				Veld is verplicht.
			}
			@if (control?.hasError('min')) {
				Minimale waarde in veld moet {{ control?.getError('min')?.min }} zijn.
			}
			@if (control?.hasError('max')) {
				Maximale waarde in veld mag {{ control?.getError('min')?.min }} zijn.
			}
			@if (control?.hasError('minlength')) {
				Minimale lengte moet {{ control?.getError('minlength')?.requiredLength }} karakter(s) zijn.
			}
			@if (control?.hasError('invalidUri')) {
				Een geldige url start met <strong>https://</strong> en bevat een toegestaan domein:
				<strong>{{ control?.getError('invalidUri')?.validDomains?.toString() }}</strong>
			}
		</small>
	`,
	standalone: true,
	imports: [CommonModule]
})
export class InvalidControlFeedbackMessage {
	@Input() control: FormControl;
}
