function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}
export default function isStyleObject(object) {
  return isObject(object) && (Array.isArray(object.steps) ||
  // `gradation` property is deprecated: it has been renamed to `steps`.
  Array.isArray(object.gradation) ||
  // `flavour` property is deprecated: it has been renamed to `labels`.
  Array.isArray(object.flavour) || typeof object.flavour === 'string' || Array.isArray(object.labels) || typeof object.labels === 'string' ||
  // `units` property is deprecated.
  Array.isArray(object.units) ||
  // `custom` property is deprecated.
  typeof object.custom === 'function');
}
var OBJECT_CONSTRUCTOR = {}.constructor;
function isObject(object) {
  return _typeof(object) !== undefined && object !== null && object.constructor === OBJECT_CONSTRUCTOR;
}
