import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotificatieConfiguratie } from '../../models/dtos/notificaties/notificatie-configuratie';

@Injectable({
	providedIn: 'root'
})
export class NotificatieConfiguratieService extends BaseApiService<NotificatieConfiguratie, NotificatieConfiguratie> {
	constructor(public httpClient: HttpClient) {
		super('notificationconfigurations', httpClient);
	}

	getAllByApplication(): Observable<NotificatieConfiguratie[]> {
		var uri = `${this.entityEndPoint}/application`;
		return this.httpClient.get<NotificatieConfiguratie[]>(uri);
	}

	addOrUpdate(configuraties: NotificatieConfiguratie[]): Observable<void> {
		var uri = `${this.entityEndPoint}/addorupdate`;
		return this.httpClient.post<void>(uri, configuraties);
	}
}
