import { Directive, HostListener, Injector } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BaseComponent } from './base.component.ts';
import { FormMode } from '../models/form-mode';
import { ConfirmationDialogService } from '../services/confirmation-dialog.service';
import { CanDeactivateComponent } from '../guards/can-deactivate.guard';
import { ToastrService } from '../services/toastr.service';

@Directive()
export abstract class FormComponent extends BaseComponent implements CanDeactivateComponent {
	routeIdParam: any;
	isSaving = false;
	isCanceling = false;

	private formMode: FormMode;

	protected formBuilder: FormBuilder;
	protected toastr: ToastrService;
	protected confirmationDialogService: ConfirmationDialogService;

	form: FormGroup | FormArray;

	get isCreateMode() {
		return this.formMode === FormMode.Create;
	}

	get isEditMode() {
		return this.formMode === FormMode.Edit;
	}

	constructor(protected override injector: Injector) {
		super(injector);

		this.formBuilder = this.injector.get(FormBuilder);
		this.toastr = this.injector.get(ToastrService);
		this.confirmationDialogService = this.injector.get(ConfirmationDialogService);

		if(this.activatedRoute.snapshot.paramMap.has('id')) 
			this.routeIdParam = this.activatedRoute.snapshot.paramMap.get('id');

		this.formMode = this.getFormMode() ?? FormMode.Create;
	}

	submit() {
		if (!this.isInitialized || this.isLoading) return;

		if (this.isSaving) {
			this.isSaving = true;
		}

		if (!this.ensureValidity()) {
			this.toastr.error('Oeps!', 'Heb je alles correct ingevoerd?');
			return;
		}

		this.afterValidityEnsured()?.then(
			() => (this.isSaving = false),
			() => (this.isSaving = false)
		);
	}

	canDeactivate(): Promise<boolean> {
		if ((this?.form?.dirty || this?.form?.touched) && !this.isCanceling) {
			return this.confirmationDialogService.open(
				'Openstaande wijzigingen',
				'<b>Let op!</b> Vergeten op te slaan? Als je doorgaat zullen de niet opgeslagen wijzigingen verloren gaan.'
			);
		}
		return new Promise((resolve, _) => resolve(true));
	}

	@HostListener('window:beforeunload', ['$event'])
	private unloadNotification($event: any) {
		if (!this.canDeactivate()) {
			$event.returnValue = true;
		}
	}

	cancel() {
		this.isCanceling = true;
		if (this.form.pristine) {
			// this.toastr.success('Geen wijzigingen', 'Er zijn geen wijzigingen gevonden.');
			this.isCanceling = false;
			return Promise.resolve(true);
		}

		return this.confirmationDialogService
			.open('Wijzigingen annuleren', 'Weet je zeker dat je wilt annuleren? De gemaakte wijzigingen zullen verloren gaan.')
			.then((confirmed) => {
				// this.reloadComponent();
				if (confirmed) {
					this.toastr.success('Wijzigingen ongedaan', 'De wijzigingen zijn succesvol ongedaan gemaakt.');
				}
				return confirmed;
			});
	}

	// Add validity checks here.
	ensureValidity(): boolean {
		return this?.form?.valid ?? false;
	}

	// After form has passed validity checks, do something here.
	abstract afterValidityEnsured(): Promise<any>;

	// Used for initializing form and validators.
	abstract initForm(): Promise<void>;

	private getFormMode() {
		if(this.activatedRoute.snapshot.data['formMode']) {
			return this.activatedRoute.snapshot.data['formMode']
		} else if(this.routeIdParam) {
			return FormMode.Edit;
		} else {
			return FormMode.Create;
		}		 
	}

	protected setFormMode(mode: FormMode) {
		this.formMode = mode;		
	}

	protected getRouteParam(param: string) {
		return this.activatedRoute.snapshot.paramMap.get(param);
	}

	protected getRouteParam$() {
		return this.activatedRoute.paramMap;
	}
}
