import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@Injectable({
	providedIn: 'root'
})
export class ConfirmationDialogService {
	constructor(private confirmationService: ConfirmationService) {}

	/**
	 * @returns if the modal is either rejected or accepted
	 */
	open(
		title: string,
		message: string,
		acceptLabel: string = 'Doorgaan',
		rejectLabel: string = 'Annuleren',
		acceptButtonStyleClass: string = 'p-button-danger',
		cancelButtonStyleClass: string = 'p-button-outlined p-button-secondary'
	) {
		return new Promise<boolean>((resolve, _) => {
			return this.confirmationService.confirm({
				header: title,
				message: message,
				acceptLabel: acceptLabel,
				rejectLabel: rejectLabel,
				acceptButtonStyleClass: acceptButtonStyleClass,
				rejectButtonStyleClass: cancelButtonStyleClass,
				icon: 'bi-exclamation-triangle',
				accept: () => {
					resolve(true);
				},
				reject: () => {
					resolve(false);
				}
			});
		});
	}
}
