import { formatDate } from '@angular/common';

export { };

export enum SortDirection {
  Ascending,
  Descending
}

declare global {
  interface Array<T> {
    firstOrDefault(predicate?: (item: T) => boolean): T;
    singleOrDefault(predicate?: (item: T) => boolean): T;
    removeItem(predicate?: (item: T) => boolean): T;
    sortByProperty(property: keyof T, direction?: SortDirection): Array<T>;
    filterByTerm(term: string): Array<T>;
  }
}

Array.prototype.sortByProperty = function <T extends object>(this: T[], property: keyof T, direction: SortDirection = SortDirection.Ascending): T[] {
  return this.sort((a: T, b: T) => {
    let value = 0;

    if (a[property] > b[property]) {
      value = direction == SortDirection.Ascending ? 0.5 : -0.5;
    } else if (a[property] < b[property]) {
      value = direction == SortDirection.Ascending ? -0.5 : 0.5;
    }

    return value;
  });
};


Array.prototype.firstOrDefault = function <T>(this: T[], predicate?: (item: T) => boolean): T {
  let result: T;
  if (!predicate) {
    result = this[0];
  } else {
    for (let element of this) {
      if (predicate(element)) {
        result = element;
        break;
      }
    }
  }
  return result;
};

Array.prototype.removeItem = function <T>(this: T[], predicate?: (item: T) => boolean) {
  do {
    var index = this.findIndex(i => predicate(i));
    if (index > -1) this.splice(index, 1);
    console.log('removeItem index', index);
  } while (index > -1)
}

Array.prototype.singleOrDefault = function <T>(this: T[], predicate?: (item: T) => boolean): T {
  let result: T

  if (predicate) {
    this.forEach(element => {
      if (predicate(element)) {
        if (result) throw new Error('The input sequence returns more then one element.');
        result = element;
      }
    });
    return result;
  } else {
    if (this.length > 1)
      throw new Error('The input sequence returns more then one element.');
    else if (this.length == 1) {
      return this[0];
    } else {
      return null;
    }
  }

  return null;
};

Array.prototype.filterByTerm = function <T extends object>(this: T[], term: string): T[] {
  if (!term) return this;

  return this.filter((obj) => {
    const objKeys = Object.keys(obj);
    for (var key of objKeys) {
      let strValue: string;
      let dateValue = Date.parse(obj[key]?.toString().replaceAll(' ', ''));
      const searchTerm = term.trim().toLowerCase();

      // convert date values to dutch format for comparison
      if (isNaN(dateValue) || dateValue < 0) {
        strValue = obj[key]?.toString().toLowerCase();
      } else {
        strValue = formatDate(new Date(dateValue), 'dd-MM-yyyy', 'nl-NL');
      }

      if (strValue && searchTerm && strValue.includes(searchTerm)) {
        return true;
      }
    }

    return false;
  });
};

