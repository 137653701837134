import { Directive, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { SubscriptionHolder } from '../subscription-holder';

@Directive()
export abstract class BaseComponent extends SubscriptionHolder {
	protected isInitialized = false;
	protected isLoading = true;
	protected activatedRoute: ActivatedRoute;
	protected router: Router;

	protected breadCrumbDefintions: MenuItem[] = [];

	constructor(protected injector: Injector) {
		super();
		this.activatedRoute = this.injector.get(ActivatedRoute);
		this.router = this.injector.get(Router);
	}

	protected getFromRoute(value: string = 'id') {
		return this.activatedRoute.snapshot.paramMap.get(value);
	}

	protected reloadComponent(): void {
		const uri = this.router.url;
		this.router.navigateByUrl('/', { skipLocationChange: true }).then((confirmed) => {
			if (!confirmed) {
				return;
			}
			this.router.navigate([uri]);
		});
	}
}
