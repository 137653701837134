import { HttpClient, HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

export class BaseApiService<TList, KEdit> {
	readonly entityEndPoint: string;

	constructor(
		public entityName: string,
		public http: HttpClient,
		public parentPath?: string
	) {
		this.entityEndPoint = `/api/${this.entityName}`;
	}

	getById(id: number): Observable<KEdit> {
		return this.http.get<KEdit>(`${this.entityEndPoint}/${id}`);
	}

	getListModelById(id: number): Observable<TList> {
		return this.http.get<TList>(`${this.entityEndPoint}/${id}/listmodel`);
	}

	getAll(): Observable<TList[]> {
		return this.http.get<TList[]>(this.entityEndPoint);
	}

	getWithQuery(queryParams: Params | string): Observable<TList[]> {
		const qParams = typeof queryParams === 'string' ? { fromString: queryParams } : { fromObject: queryParams };
		const params = new HttpParams(qParams);
		return this.http.get<TList[]>(this.entityEndPoint, { params });
	}

	add(entity: KEdit): Observable<KEdit> {
		return this.http.post<KEdit>(this.entityEndPoint, entity);
	}
	
	createWithFormData(formData: FormData): Observable<TList> {
		return this.http.post<TList>(`${this.entityEndPoint}`, formData);
	}

	delete(id: number) {
		return this.http.delete(`${this.entityEndPoint}/${id}`);
	}

	update(id: number, entity: KEdit | FormData): Observable<KEdit> {
		return this.http.put<KEdit>(`${this.entityEndPoint}/${id}`, entity);
	}

	updateWithFormData(id: number, formData: FormData): Observable<TList> {
		return this.http.put<TList>(`${this.entityEndPoint}/${id}`, formData);
	}

}
