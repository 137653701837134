import { AfterViewInit, Component, Input, OnInit, signal } from '@angular/core';
import { Message, SharedModule } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';

@Component({
	selector: 'p-message',
	template: `<p-messages [(value)]="value" [styleClass]="styleClass" [closable]="closable" [enableService]="false"></p-messages>`,
	imports: [MessagesModule, SharedModule],
	standalone: true
})
export class MessageComponent implements OnInit, AfterViewInit {
	@Input() summary: string;
	@Input() severity: 'info' | 'warn' | 'success' | 'error' | 'secondary' | 'constrast' = 'info';
	@Input() closable = false;
	@Input() styleClass: any;

	value: Message[] = [];

	constructor() {}

	ngOnInit(): void {
		this.value = [
			{
				severity: this.severity,
				detail: this.summary
			}
		];
	}

	ngAfterViewInit(): void {}
}
