import { Pipe, PipeTransform } from '@angular/core';
import { EnumEntry } from '../models/enum-key.interface';
import { formatEnumValueAsReadableText } from '../functions/string';
import { sortArrayByKey } from '../functions/array';

@Pipe({
	name: 'enumKeys',
	standalone: true
})
export class EnumKeysPipe implements PipeTransform {
	transform(enumType: any, filterByValue = false): EnumEntry[] {
		const results: EnumEntry[] = [];
		for (const enumValue in enumType) {
			if (enumValue) {
				const formatted = formatEnumValueAsReadableText(enumValue, enumType);
				if (formatted) {
					results.push({ key: +enumValue, value: formatted });
				}
			}
		}

		if (filterByValue) {
			results.sort(sortArrayByKey('value'));
		}

		return results;
	}
}
