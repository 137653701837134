import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, switchMap, takeUntil, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Thema } from '../models/thema';
import { AuthenticationService } from './authentication.service';

@Injectable({
	providedIn: 'root'
})
export class ThemaService {
	private _theme: Thema;
	private _isInitialized$ = new BehaviorSubject(false);

	switchTheme = false;

	get theme() {
		return this._theme;
	}

	get isInitialized$() {
		return this._isInitialized$.asObservable().pipe(filter((initted) => initted));
	}

	constructor(private httpClient: HttpClient) {
		this.loadCachedThema();
		this.loadThema();
	}

	private setThema(theme: string) {
		let themeHref = theme + '.css';
		const linkElement = document.createElement('link');
		linkElement.id = 'theme-link';
		linkElement.rel = 'stylesheet';
		linkElement.href = themeHref;
		document.head.appendChild(linkElement);
	}

	softReloadAppearance() {
		this.loadCachedThema();
	}

	private loadThema() {
		this.httpClient.get<Thema>('api/thema').subscribe((theme) => this.initAppearance(theme));
	}

	private initAppearance(theme: Thema) {
		if (theme == null) {
			return;
		}
		this._theme = theme;
		this.cacheThema();
		this.setFavicon(theme.faviconUri);
		this.setThema(theme.stylingThema);
		this._isInitialized$.next(true);
	}

	private cacheThema(): void {
		// Cache the tenant layout in local storage
		localStorage.setItem('thema', JSON.stringify(this._theme));
	}

	private loadCachedThema(): void {
		// Load the tenant layout from local storage
		const layout = localStorage.getItem('thema');

		if (layout != null) {
			this.initAppearance(JSON.parse(layout));
		}
	}

	private setFavicon(iconPath: string): void {
		const link: HTMLLinkElement = document.querySelector("link[rel~='icon']") || document.createElement('link');
		link.type = 'image/x-icon';
		link.rel = 'icon';
		link.href = iconPath;
		document.head.appendChild(link);
	}
}
