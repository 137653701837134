import { Component, Input } from '@angular/core';
import { FormFieldTitleComponent } from '../form-field-title/form-field-title.component';
import { FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InvalidControlFeedbackMessage } from '../invalid-control-feedback-message/invalid-control-feedback-message.component';

@Component({
	selector: 'app-form-field',
	template: `
		<div class="flex flex-column gap-1">
			@if (!hideTitle) {
				<app-form-field-title [titleIcon]="titleIcon" [title]="title"></app-form-field-title>
			}
			<ng-content></ng-content>
			<div class="form-text text-sm">{{ explanation }}</div>
			<div class="invalid-feedback">
				<app-invalid-control-feedback-message [control]="control"></app-invalid-control-feedback-message>
			</div>
		</div>
	`,
	styleUrls: ['./form-field.component.scss'],
	standalone: true,
	imports: [CommonModule, FormFieldTitleComponent, InvalidControlFeedbackMessage]
})
export class FormFieldComponent extends FormFieldTitleComponent {
	@Input() hideTitle = false;
	@Input() explanation: string = undefined;
	@Input() control: FormControl;

	constructor() {
		super();
	}
}
